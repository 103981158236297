import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Parallax } from 'react-parallax';
import { Typography, Button, Card } from 'antd';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
  Autoplay,
  Keyboard,
  Navigation,
  HashNavigation,
} from 'swiper';
import { Div } from '../../framework/Div/Div';
import { setFlyoutOpen, setMenuMode } from '../../store/app.slice';
import Menu from '../../component/Menu/Menu';
import { isClient } from '../../helper/detect';
import { useClientWindow, useResetScroll } from '../../helper/hook';
import Footer from '../../component/Footer/Footer';
import { serviceDomain } from '../../../config/app.config';

import 'swiper/swiper.min.css';
import 'swiper/scss';

import './Home.scss';

import BackSvg from '../../framework/Icon/BackSvg';
import NextSvg from '../../framework/Icon/NextSvg';
import EnquiryForm from './EnquiryForm';
import { createWaLink } from '../../helper/message';
import { WhatsAppOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

SwiperCore.use([Keyboard, Autoplay]);

const MOBILE_RES_THRESHOLD = 600;

const HomePage = () => {
  useResetScroll();
  const dispatch = useDispatch();
  const history = useHistory();
  const window = useClientWindow();
  const sectionRef = useRef<HTMLElement>(null);
  const [objectPosition, setObjectPosition] = useState('100%');
  const [windowSize, setWindowSize] = useState([
    window?.innerWidth,
    window?.innerHeight,
  ]);
  const onScroll = () => {
    const infoCardY = sectionRef.current?.getClientRects()[0].y;
    dispatch(
      setMenuMode({ isShrank: typeof infoCardY === 'number' && infoCardY < 0 })
    );
  };

  useEffect(() => {
    dispatch(setFlyoutOpen({ isOpen: false }));
  }, []);

  useEffect(() => {
    window?.addEventListener('scroll', onScroll);

    return () => {
      window?.removeEventListener('scroll', onScroll);
    };
  }, []);

  useEffect(() => {
    setObjectPosition(
      isClient && document.body.clientWidth < MOBILE_RES_THRESHOLD
        ? '90%'
        : '100%'
    );

    const handleWindowResize = () => {
      setWindowSize([window?.innerWidth, window?.innerHeight]);
    };

    window?.addEventListener('resize', handleWindowResize);

    return () => {
      window?.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const goToHirerRegisterPage = () => {
    history.push('/hirer/register');
  };

  return (
    <div className='page-home'>
      <Helmet>
        <title>HeyCast.Me Tech-Driven Casting Platform</title>
        <meta
          name='description'
          content='Connecting acting talents with filmmakers and producers'
        />
        <meta
          property='og:title'
          content='HeyCast.Me Tech-Driven Casting Platform'
        />
        <meta property='og:url' content={`${serviceDomain.ui}`} />
        <meta
          property='og:description'
          content='Connecting acting talents with filmmakers and producers'
        />
      </Helmet>
      <Menu
        isMobile={
          windowSize[0] == undefined
            ? undefined
            : windowSize[0] < MOBILE_RES_THRESHOLD
        }
      />
      {windowSize[0] && windowSize[0] < MOBILE_RES_THRESHOLD && (
        <video
          key={'bg-video-vertical'}
          autoPlay
          muted
          loop
          className='bg-video'
          id='bg-video-vertical'
        >
          <source
            src='https://heycastme-assets.s3.ap-southeast-1.amazonaws.com/mobile/mobile.mp4'
            type='video/mp4'
          />
        </video>
      )}
      {!windowSize[0] ||
        (windowSize[0] >= MOBILE_RES_THRESHOLD && (
          <video
            key={'bg-video-horizontal'}
            autoPlay
            muted
            loop
            className='bg-video'
            id='bg-video-horizontal'
          >
            <source
              src='https://heycastme-assets.s3.ap-southeast-1.amazonaws.com/web/bg-video.mp4'
              type='video/mp4'
            />
          </video>
        ))}
      <Parallax
        strength={500}
        blur={{ min: -15, max: 15 }}
        bgImageStyle={{
          height: '100vh',
          width: '100%',
          objectFit: 'cover',
          objectPosition,
        }}
      >
        <section className='section-title' style={{ position: 'relative' }}>
          <Div className='title-container'>
            <Title level={1}>
              HeyCast.me, a Digital-led Job Platform for Entertainment Industry
            </Title>
            <Text>The only platform you need for talents scouting.</Text>
            <Button
              style={{ marginTop: 57 }}
              onClick={() => {
                document
                  .querySelector('.contact-us')
                  ?.scrollIntoView({ behavior: 'smooth' });
              }}
            >
              Get In Touch
            </Button>
          </Div>
        </section>
      </Parallax>
      <Div style={{ background: '#fff' }}>
        <section className='section' ref={sectionRef}>
          <Div className='about-us'>
            <Div className='center'>
              <Title>
                About <br />
                HeyCast
              </Title>
            </Div>
            <Div>
              <Title level={4}>
                Designed by industry professionals who&apos;ve been in your
                shoes.{' '}
              </Title>
              <Title level={4}>
                HeyCast streamlines casting from end to end.{' '}
              </Title>
              <Text>
                From talent registration to on-set administration and timesheet
                generation, we&apos;re making your life easier, more efficient,
                and more transparent. HeyCast brings you a digitally-led
                solution to film, television production, commercial advertising,
                and influencer marketing, revolutionizing the way you cast and
                manage talent.
              </Text>
            </Div>
          </Div>
        </section>
      </Div>

      <section className='section what-makes-us-special'>
        <Title>What Makes Us Special</Title>

        <img
          className='what-makes-us-special-image'
          src='https://heycastme-assets.s3.ap-southeast-1.amazonaws.com/web/what-makes-us-special.png'
        />
        <img
          className='mobile mobile-only production-film-image'
          src='https://heycastme-assets.s3.ap-southeast-1.amazonaws.com/mobile/what-makes-us-special_production-film.png'
        />
        <img
          className='mobile mobile-only arrow'
          src='https://heycastme-assets.s3.ap-southeast-1.amazonaws.com/mobile/what-makes-us-special_arrow.png'
        />
        <img
          className='mobile mobile-only actor'
          src='https://heycastme-assets.s3.ap-southeast-1.amazonaws.com/mobile/what-makes-us-special_actor.png'
        />
      </section>
      <section className='section what-we-do'>
        <Div className='what-we-do-title-container'>
          <Title>What We Do</Title>
        </Div>
        <Div className='content'>
          <Title className='banner-title'>Talent Discovery</Title>
          <Text className='description'>
            Discover and hire acting talents, artists, influencers, and models
            instantly with HeyCast&apos;s real-time talent scouting. Whether
            it&apos;s for a commercial, film, artistic project, or brand boost,
            we ensure efficiency and effectiveness in talent discovery. Our
            platform connects you directly with the perfect match, eliminating
            extensive searches and saving you valuable time.
          </Text>
        </Div>
        <Button
          onClick={() => {
            window?.open(
              createWaLink('Hi, I would like to find out more.'),
              '_blank'
            );
          }}
        >
          Get Started
        </Button>
      </section>
      <img
        className='mobile mobile-image'
        src='https://heycastme-assets.s3.ap-southeast-1.amazonaws.com/mobile/instant-talent.png'
      />

      <section className='section effortless-casting'>
        <Div className='text-container'>
          <Div>
            <Title className='banner-title'>Effortless Casting</Title>
            <Title className='banner-title'>Experience</Title>
          </Div>
          <Div className='content'>
            <Text className='description'>
              Experience the future of casting with our user-friendly platform.
              We simplify the process of browsing profiles, viewing portfolios,
              and watching showreels for all your casting needs, from models for
              photoshoots to actors for video ads. Our goal is to streamline
              casting, save time, and provide transparency - making it efficient
              and stress-free, so you can save time and focus on your creative
              vision.
            </Text>
          </Div>
          <Button
            onClick={() => {
              window?.open(
                createWaLink('Hi, I would like to find out more.'),
                '_blank'
              );
            }}
          >
            Find Talents Now
          </Button>
        </Div>
      </section>
      <img
        className='mobile mobile-image'
        src='https://heycastme-assets.s3.ap-southeast-1.amazonaws.com/mobile/effortless-casting-experience.png'
      />
      <section className='section comprehensive-support'>
        <Div className='content'>
          <Title className='banner-title'>Comprehensive Support</Title>
          <Text className='description'>
            You can count on our dedicated professionals for unparalleled
            support throughout your casting journey. We do offer guidance in
            talent selection, assist in organizing auditions, and provide
            valuable advice on industry trends. Your projects matter to us, and
            we&apos;re committed to your smooth and successful casting
            experience, saving you time at every step.
          </Text>
        </Div>
        <Button
          onClick={() => {
            window?.open(
              createWaLink('Hi, I would like to find out more.'),
              '_blank'
            );
          }}
        >
          Get Started
        </Button>
      </section>
      <img
        className='mobile mobile-image'
        src='https://heycastme-assets.s3.ap-southeast-1.amazonaws.com/mobile/comprehensive-support.png'
      />
      <section className='section influencer-marketing-simplified'>
        <Div className='text-container'>
          <Div>
            <Title className='banner-title'>Influencer Marketing</Title>
            <Title className='banner-title'>Simplified</Title>
          </Div>
          <Div className='content'>
            <Text className='description'>
              HeyCast simplifies influencer marketing with our KOL service. We
              offer comprehensive KOL management and campaign solutions to
              streamline your influencer marketing efforts and boost your
              brand&apos;s visibility. We help you harness the power of
              influencers to achieve your marketing goals.
            </Text>
          </Div>
          <Button
            onClick={() => {
              window?.open(
                createWaLink('Hi, I would like to find out more.'),
                '_blank'
              );
            }}
          >
            Find KOL
          </Button>
        </Div>
      </section>
      <img
        className='mobile mobile-image'
        src='https://heycastme-assets.s3.ap-southeast-1.amazonaws.com/mobile/influencer-marketing-simplified.png'
      />
      <section className='section testimonial'>
        <Title>Testimonial</Title>
        <Div className='testimonial-swiper'>
          <button className='navigation-button swiper-back-button-testimonial'>
            <BackSvg />
          </button>
          <Swiper
            modules={[Navigation, HashNavigation]}
            slidesPerView={1}
            hashNavigation
            keyboard
            navigation={{
              enabled: true,
              nextEl: '.swiper-next-button-testimonial',
              prevEl: '.swiper-back-button-testimonial',
            }}
            centeredSlides
            centeredSlidesBounds
            rewind
            className='swiper'
            autoplay={{ delay: 2000, disableOnInteraction: false }}
          >
            {[
              {
                text: '"Our experience with Heycast.me has been marked by efficiency and transparency. The platform simplifies the talent hiring process, and the transparent fee structure including the pricing is clear and fair – which is pretty rare in our industry."',
                name: 'Max Ma',
                title: 'Producer of USB Brand Solution',
              },
              {
                text: "\"Heycast.me's talent pool is impressive. We've discovered incredibly talented individuals for our projects. The platform's user-friendly interface and in-depth talent profiles make it easy to find the perfect fit.\"",
                name: 'Asher Yeap',
                title: 'Director of Scarlet Production',
              },
              {
                text: '"Heycast.me has completely transformed the talent hiring game for us. This platform has revolutionized the way we find and hire talents and tremendously improve our talent search and hiring process. We can\'t imagine going back to the old way of doing things."',
                name: 'Jason',
                title: 'Casting Director',
              },
              {
                text: '"Heycast is more than a time-saver; it\'s a game-changer. It\'s saved us countless casting hours, which, as a producer cum director, is priceless. The platform houses skilled performers who bring our scripts to life. The support and collaboration with the entire team have been outstanding. Heycast is now an essential tool in entertainment. I highly recommend it for streamlining casting processes and discovering top talent."',
                name: 'Luke Tay',
                title: 'Director of Madboy Productions',
              },
              {
                text: '"Heycast simplifies talent discovery for video producers like me. It\'s easy to find the right talent quickly, thanks to their user-friendly platform and diverse talent pool. It\'s a valuable resource for video projects."',
                name: 'Melvin',
                title: 'Producer of H Live',
              },
              {
                text: '"Working with Heycast was a game-changer! They not only let us access the perfect Key Opinion Leaders for our client but also made the entire process cost-effective. Direct access to KOLs and smooth communication - highly recommended!"',
                name: 'Ron Pang',
                title:
                  'Digital Media Deputy Manager of Trinity42 Digital Sdn Bhd',
              },
            ].map((test, idx) => (
              <SwiperSlide key={test.name + idx} className='centered-slide'>
                <Card>
                  <Text>{test.text}</Text>
                  <hr />
                  <Title level={5}>{test.name}</Title>
                  <Text>{test.title}</Text>
                </Card>
              </SwiperSlide>
            ))}
          </Swiper>
          <button className='navigation-button swiper-next-button-testimonial'>
            <NextSvg />
          </button>
        </Div>
      </section>
      <hr style={{ width: '80%' }} />
      <section className='section our-client'>
        <Title>Our Client</Title>
        <Div className='our-client-inner'>
          <button className='navigation-button swiper-back-button-our-client'>
            <BackSvg />
          </button>
          <Swiper
            modules={[Navigation, HashNavigation]}
            slidesPerView={4}
            hashNavigation
            keyboard
            navigation={{
              enabled: true,
              nextEl: '.swiper-next-button-our-client',
              prevEl: '.swiper-back-button-our-client',
            }}
            centeredSlides
            centeredSlidesBounds
            rewind
            className='swiper'
            autoplay={{ delay: 2000, disableOnInteraction: false }}
          >
            {[
              'HP.png',
              'King_Koil.png',
              'Hada_Labo.png',
              'Cuckcoo.png',
              'SilkyGirl.png',
              '8TV_Behind_the_Scam.png',
              'Ecoworld.png',
              'GOGO_STEAMBOAT_RESTAURANT.png',
              'Kyochon.png',
              'Airbot_Aura.png',
              'Algow_Clinic.png',
              'Amari_Hotel.png',
              'KLCC_IMPIANA_HOTEL.png',
              'Bottoms_Lab.png',
              'Newverses.png',
              'Pastry_World.png',
              'Denco_AutoParts.png',
              'UiTM.png',
              'Nala_Group.png',
              'Faire_Development_Sdn_Bhd.png',
              'Gamuda.png',
              'Signature_Kitchen.png',
              'genius_mind_academy.png',
              'J.Bovier.png',
              'Scientex.png',
              'Lanouva.png',
              'LOURISH.png',
              'Mzec_Development.png',
              'Neuvida.png',
              'Great_Finance_Malaysia.png',
              'Powin.png',
              'AM2U.png',
              'Bright_Finance.png',
            ].map((logo) => (
              <SwiperSlide key={logo} className='centered-slide'>
                <Div className='logo-container'>
                  <img
                    src={`https://heycastme-assets.s3.ap-southeast-1.amazonaws.com/logo/${logo}`}
                  />
                </Div>
              </SwiperSlide>
            ))}
          </Swiper>
          <Swiper
            modules={[Navigation, HashNavigation]}
            slidesPerView={1}
            hashNavigation
            keyboard
            navigation={{
              enabled: true,
              nextEl: '.swiper-next-button-our-client',
              prevEl: '.swiper-back-button-our-client',
            }}
            centeredSlides
            centeredSlidesBounds
            rewind
            className='swiper-mobile'
            autoplay={{ delay: 2000, disableOnInteraction: false }}
          >
            {[
              'HP.png',
              'King_Koil.png',
              'Hada_Labo.png',
              'Cuckcoo.png',
              'SilkyGirl.png',
              '8TV_Behind_the_Scam.png',
              'Ecoworld.png',
              'GOGO_STEAMBOAT_RESTAURANT.png',
              'Kyochon.png',
              'Airbot_Aura.png',
              'Algow_Clinic.png',
              'Amari_Hotel.png',
              'KLCC_IMPIANA_HOTEL.png',
              'Bottoms_Lab.png',
              'Newverses.png',
              'Pastry_World.png',
              'Denco_AutoParts.png',
              'UiTM.png',
              'Nala_Group.png',
              'Faire_Development_Sdn_Bhd.png',
              'Gamuda.png',
              'Signature_Kitchen.png',
              'genius_mind_academy.png',
              'J.Bovier.png',
              'Scientex.png',
              'Lanouva.png',
              'LOURISH.png',
              'Mzec_Development.png',
              'Neuvida.png',
              'Great_Finance_Malaysia.png',
              'Powin.png',
              'AM2U.png',
              'Bright_Finance.png',
            ].map((logo) => (
              <SwiperSlide key={logo} className='centered-slide'>
                <Div className='logo-container'>
                  <img
                    src={`https://heycastme-assets.s3.ap-southeast-1.amazonaws.com/logo/${logo}`}
                  />
                </Div>
              </SwiperSlide>
            ))}
          </Swiper>
          <button className='navigation-button swiper-next-button-our-client'>
            <NextSvg />
          </button>
        </Div>
      </section>
      <section className='section featured'>
        <Title level={4}>Featured On</Title>
        <Div flex className='logos'>
          {[
            {
              logo: 'BFM.png',
              url: 'https://www.bfm.my/podcast/enterprise/open-for-business/casting-made-easy',
            },
            { logo: 'Cityplus_FM.png' },
            {
              logo: 'Malaysian_business.png',
              url: 'https://www.malaysian-business.com/index.php/wordpress/item/8310-cradle-wraps-up-its-second-series-of-cradle-circle',
            },
            {
              logo: 'Focus_Malaysia.png',
              url: 'https://focusmalaysia.my/cradle-wraps-up-its-second-series-of-cradle-circle/',
            },
            {
              logo: 'Listen_Notes.png',
              url: 'https://www.listennotes.com/podcasts/open-for-business/casting-made-easy-sT0qlng7tZ8/',
            },
          ].map((item) => (
            <Div key={item.logo} className='logo-container' noGrow>
              <a href={item.url} target='_blank' rel='noreferrer'>
                <img
                  src={`https://heycastme-assets.s3.ap-southeast-1.amazonaws.com/logo/${item.logo}`}
                />
              </a>
            </Div>
          ))}
        </Div>
      </section>
      <section className='section endorse'>
        <Title level={4}>Endorsed and funded by</Title>
        <Div flex className='logos'>
          <Div className='logo-container'>
            <img src='https://heycastme-assets.s3.ap-southeast-1.amazonaws.com/cradle-logo.png' />
          </Div>
          <Div className='logo-container'>
            <img src='https://heycastme-assets.s3.ap-southeast-1.amazonaws.com/cip-spark-logo.png' />
          </Div>
        </Div>
      </section>
      <section className='section lights-camera-action'>
        <Div>
          <Title>Lights, Camera, Action!</Title>
          <Title level={5}>Your casting journey begins here.</Title>
        </Div>
      </section>
      <section className='section contact-us'>
        <EnquiryForm />
      </section>
      <Footer />
      <a
        className='float-button'
        href={createWaLink('Hi, I would like to find out more.')}
        target='_blank'
        rel='noreferrer'
      >
        <WhatsAppOutlined />
      </a>
    </div>
  );
};

export default HomePage;
