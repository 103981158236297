import React, { FunctionComponent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Div } from '../../framework';
import Title from 'antd/lib/typography/Title';
import { Button, Form, Input, Select } from 'antd';
import { InfluencerType } from '../../helper/constants';
import { FieldNamesType } from 'antd/lib/cascader';
import './EnquiryForm.scss';
import { useEnquiryService } from '../../service/enquiry.service';

const EnquiryForm: FunctionComponent = () => {
  const subjectOptions = [
    { value: InfluencerType.KOL, label: 'KOL' },
    { value: InfluencerType.ACTOR, label: 'Actor/Actress' },
    { value: InfluencerType.OTHERS, label: 'Others' },
  ];

  const enum SubmitStatus {
    SENDING = 'sending',
    SENT = 'sent',
    IDLE = 'idle',
  }

  const history = useHistory();
  const enquiryService = useEnquiryService();
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState<SubmitStatus>(
    SubmitStatus.IDLE
  );

  const submitEnquiryForm = async (formValues: string) => {
    setIsSubmitting(SubmitStatus.SENDING);
    try {
      await enquiryService.createEnquiry(formValues);
      setIsSubmitting(SubmitStatus.SENT);
      form.resetFields();

      history.push('/enquiry-submitted');
    } catch {
      setIsSubmitting(SubmitStatus.IDLE);
    }
  };

  const getSubmitStatus = () => {
    if (isSubmitting === SubmitStatus.SENDING) {
      return { message: 'Sending', disabled: true };
    } else if (isSubmitting === SubmitStatus.SENT) {
      return { message: 'Sent', disabled: false };
    }
    return { message: 'Send', disabled: false };
  };

  return (
    <>
      <Div className='contact-us-title'>Contact Us</Div>
      <Div className='contact-us-inner'>
        <Div className='left-container'>
          <Title>Find Your Star</Title>
          <Title className='bigger'>Today!</Title>
          <span className='contact-us-image'>
            <img src='https://heycastme-assets.s3.ap-southeast-1.amazonaws.com/web/contact-us-image.png' />
          </span>
        </Div>
        <Form
          form={form}
          autoComplete='off'
          className='right-container'
          name='enquiry-form'
          initialValues={{ remember: true }}
          onFinish={submitEnquiryForm}
          onFinishFailed={(e) => console.log(e, 'error')}
        >
          <Form.Item<FieldNamesType>
            label='Name:'
            name='fullName'
            rules={[{ required: true, message: 'Please enter your name' }]}
          >
            <Input bordered={false} />
          </Form.Item>

          <Form.Item<FieldNamesType>
            label='Email:'
            name='email'
            rules={[
              { required: true, message: 'Please enter your email' },
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
            ]}
          >
            <Input
              type='email'
              bordered={false}
              onInput={(e) => {
                const { value, selectionStart, selectionEnd } = e.currentTarget;
                e.currentTarget.value = value.toLowerCase();
                e.currentTarget.setSelectionRange(selectionStart, selectionEnd); // to preserve cursor position
              }}
            />
          </Form.Item>
          <Form.Item<FieldNamesType>
            label='Phone Number:'
            name='phoneNumber'
            rules={[
              { required: true, message: 'Please enter your phone number' },
            ]}
          >
            <Input type='tel' bordered={false} />
          </Form.Item>
          <Form.Item<FieldNamesType>
            label='Subject:'
            name='subject'
            initialValue={InfluencerType.KOL}
            rules={[{ required: true, message: 'Please enter your subject' }]}
          >
            <Select style={{ width: '100%' }} options={subjectOptions} />
          </Form.Item>

          <Form.Item<FieldNamesType>
            label='Message:'
            name='message'
            rules={[{ required: true, message: 'Please enter your message' }]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item>
            <Button
              loading={getSubmitStatus().disabled}
              style={{ width: '100%' }}
              htmlType='submit'
              className={`submit ${isSubmitting}`}
              disabled={getSubmitStatus().disabled}
            >
              {getSubmitStatus().message}
            </Button>
          </Form.Item>
        </Form>
      </Div>
    </>
  );
};

export default EnquiryForm;
