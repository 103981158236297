import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import Menu from '../../component/Menu/Menu';
import Footer from '../../component/Footer/Footer';
import { Div } from '../../framework';
import { HIRER_ROUTES } from '../../route/constant';

import './EnquirySubmitted.scss';

const { Title, Paragraph } = Typography;

const EnquirySubmitted = () => {
  return (
    <Div className='page-enquiry-submitted'>
      <Menu />
      <Helmet>
        <title>Thanks for filling out that form! - HeyCast.Me</title>
        <meta
          name='description'
          content='The right role. The right talent. The right platform.'
        />
      </Helmet>

      <Div flex className='content'>
        <Div>
          <Div className='thankyou-image'></Div>
        </Div>
        <Div>
          <Title level={1}>Thanks for filling out that form!</Title>
          <Title level={2}>You&apos;re Awesome.</Title>
          <Paragraph>
            You&apos;ll hear from us shortly. In the meantime, if you are a
            talent,{' '}
            <Link to='/auth/talent/register?src=enquiry'>
              create a talent profile
            </Link>
            . If you are looking for talents,{' '}
            <Link to={HIRER_ROUTES.REGISTER + '?src=enquiry'}>
              sign up your hirer profile
            </Link>
            .
          </Paragraph>
        </Div>
      </Div>

      <Div noGrow className='footer'>
        <Footer />
      </Div>
    </Div>
  );
};

export default EnquirySubmitted;
