import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import Menu from '../../component/Menu/Menu';
import Footer from '../../component/Footer/Footer';
import { Div } from '../../framework';
import { HIRER_ROUTES } from '../../route/constant';

import './InvalidAuthLink.scss';

const { Title, Paragraph } = Typography;

const InvalidAuthLink = () => {
  return (
    <Div className='page-invalid-link'>
      <Menu />
      <Helmet>
        <title>Oops! Link&apos;s dead or expired. - HeyCast.Me</title>
        <meta
          name='description'
          content='The right role. The right talent. The right platform.'
        />
      </Helmet>

      <Div className='content'>
        <Title level={1}>Oops!</Title>
        <Title level={2}>Link&apos;s dead or expired.</Title>
        <Paragraph>
          Looks like the link you&apos;re trying to open isn&apos;t working. It
          might be expired or just not right. Try{' '}
          <Link to={HIRER_ROUTES.LOGIN}>login</Link> again.
        </Paragraph>
      </Div>

      <Div noGrow className='footer'>
        <Footer />
      </Div>
    </Div>
  );
};

export default InvalidAuthLink;
